import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTripFilterContext } from '@vakantiesnl/services/src/context/useTripFilterContext/useTripFilterContext';
import {
	type FlightAlternativesResponse,
	useCreateFlightAlternativesRequestBody,
} from '@vakantiesnl/services/src/search/requests';
import { mapCompactAccommodations } from '@vakantiesnl/services/src/search/vaknl-mapper';
import { isPackageOffer } from '@vakantiesnl/services/src/util';
import { errorHandler, parseErrorToReport } from '@vakantiesnl/services/src/util/errorHandling';
import { fetchHandler } from '@vakantiesnl/services/src/util/fetchHandler';
import { fetchVakNL } from '@vakantiesnl/services/src/util/vaknl-fetch';
import { type OfferItem } from '@vakantiesnl/types/src/search';

import { getSelectedOfferByFlight } from './selectHelpers';
import { useAccoOfferStore } from '../../../stores/accommodationStore/useAccoStore';
import { useGetAccommodationByEntityId } from '../useGetAccommodationByEntityId';

// NB: Only call this hook once per page!
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useGetFlightAlternativeOffers() {
	const departureDateOffer = useAccoOfferStore((s) => s.departureDateOffer);
	const setFlightsOffer = useAccoOfferStore((s) => s.setFlightsOffer);
	const giataId = useGetAccommodationByEntityId().accommodation.giataId;
	const { state } = useTripFilterContext();

	const flightAlternativesRequestBody = useCreateFlightAlternativesRequestBody(departureDateOffer);

	const query = useQuery({
		queryKey: ['flightAlternatives', departureDateOffer?.id || 'none', flightAlternativesRequestBody],
		queryFn: () => fetchFlightAlternativesOffers(giataId, flightAlternativesRequestBody, departureDateOffer),
		enabled: !!departureDateOffer,
	});

	useEffect(() => {
		if (!query.data?.all || !departureDateOffer) {
			return undefined;
		}

		const selectFlight = getSelectedOfferByFlight(state.selected.flight, query.data?.all, departureDateOffer);
		setFlightsOffer(selectFlight);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.selected.flight, query.data?.all, flightAlternativesRequestBody]);

	return {
		isLoadingFlightAlternativeOffers: query.isLoading,
		flightAlternativeOffers: query.data,
	};
}

type FlightAlternativeOffersResponse = {
	all: OfferItem[];
};

async function fetchFlightAlternativesOffers(
	giataId: number | undefined,
	requestBody: string,
	offer: OfferItem | undefined,
): Promise<FlightAlternativeOffersResponse | undefined> {
	if (!requestBody || !offer || !giataId) {
		return undefined;
	}

	try {
		const result = await fetchHandler<FlightAlternativesResponse>({
			fetchFn: () =>
				fetchVakNL({
					input: new Request(
						`${process.env.NEXT_PUBLIC_SEARCH_ENDPOINT_URL}/api/v1/accommodation/${giataId}/flight-alternatives?check_availability=true`,
						{
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: requestBody,
						},
					),
				}),
			errorMessage: 'Cannot fetch flight alternative offers',
		});

		const accommodations = mapCompactAccommodations(result.accommodations);
		const allOffers = accommodations.length ? accommodations[0].offers.filter(isPackageOffer) : [];

		return {
			all: allOffers,
		};
	} catch (e) {
		errorHandler.report(parseErrorToReport(e, `Error in fetchDepartureDateOffers`));

		return {
			all: [],
		};
	}
}
